import type { Transport, Transporter } from "@sveltejs/kit";
import { Option, Schema } from "effect";
import { DateTime } from "luxon";
import { CalViewState } from "./routes/(authenticated)/dashboard/calendar/view/CalViewState.svelte";
import { EmailThread } from "$lib/emails/entities/emailMessage";

const createSchemaTransporter = <A, I>(schema: Schema.Schema<A, I>): Transporter<A, I> => {
    const encode = Schema.encodeUnknownOption(schema);
    const decode = Schema.decodeSync(schema);
    return {
        encode: (value: unknown) => encode(value).pipe(Option.getOrElse(() => false as const)),
        decode,
    };
};

export const transport = {
    Option: createSchemaTransporter(Schema.Option(Schema.Unknown)),
    Zoned: createSchemaTransporter(Schema.DateTimeZoned),
    CalViewState: createSchemaTransporter(CalViewState),
    LuxonDateTime: {
        encode: (value: unknown) => DateTime.isDateTime(value) && value.toISO(),
        decode: (value: string) => DateTime.fromISO(value),
    },
    EmailThread: createSchemaTransporter(EmailThread),
} satisfies Transport;
