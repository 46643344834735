import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67')
];

export const server_loads = [0,2,5,7,10,12,13,14,15];

export const dictionary = {
		"/": [~16],
		"/auth/change-provider/[provider=provider]": [~47],
		"/(authenticated)/dashboard/(dashboard)": [17,[2,3]],
		"/(authenticated)/dashboard/actions": [18,[2,3]],
		"/(authenticated)/dashboard/actions/[actionId]/[sessionId]": [19,[2,3]],
		"/(authenticated)/dashboard/assist": [20,[2,3]],
		"/(authenticated)/dashboard/billing": [~21,[2,3,4]],
		"/(authenticated)/dashboard/billing/success": [22,[2,3,4]],
		"/(authenticated)/dashboard/calendar-log": [26,[2,3]],
		"/(authenticated)/dashboard/calendar": [23,[2,3]],
		"/(authenticated)/dashboard/calendar/view": [24,[2,3]],
		"/(authenticated)/dashboard/calendar/view/[viewId]": [~25,[2,3]],
		"/(authenticated)/dashboard/connections/calendar": [27,[5]],
		"/(authenticated)/dashboard/connections/calendar/preparing": [28,[5]],
		"/(authenticated)/dashboard/connections/email": [29,[5]],
		"/(authenticated)/dashboard/connections/teams": [30,[5]],
		"/(authenticated)/dashboard/contacts": [31,[2,3,6]],
		"/(authenticated)/dashboard/contacts/[id]": [32,[2,3,6]],
		"/(authenticated)/dashboard/emails": [33,[2,3]],
		"/(authenticated)/dashboard/emails/popout/[threadId]/[execId]": [35,[2,8]],
		"/(authenticated)/dashboard/emails/[[folderExec]]/[folder]": [34,[2,3,7]],
		"/(authenticated)/dashboard/schedule": [36,[2,3]],
		"/(authenticated)/dashboard/schedule/new": [38,[2,3]],
		"/(authenticated)/dashboard/schedule/[scheduleId]": [37,[2,3]],
		"/(authenticated)/dashboard/settings": [~39,[2,3]],
		"/(authenticated)/dashboard/settings/calendar/[execId]": [40,[2,3]],
		"/(authenticated)/dashboard/tasks": [41,[2,3]],
		"/(authenticated)/dashboard/tasks/new": [44,[2,3]],
		"/(authenticated)/dashboard/tasks/[agendaId]": [42,[2,3]],
		"/(authenticated)/dashboard/tasks/[agendaId]/view": [43,[2,3]],
		"/(authenticated)/dashboard/team": [45,[2,3]],
		"/(authenticated)/dashboard/team/add": [46,[2,3]],
		"/errors/mailbox-inactive": [~48,[9]],
		"/errors/missing-scopes/[provider=provider]": [49,[9]],
		"/internal": [50,[10]],
		"/internal/organizations": [~51,[10]],
		"/internal/organizations/[orgId]": [~52,[10]],
		"/internal/users": [~53,[10]],
		"/internal/users/[execId]": [~54,[10]],
		"/invite/[shortCode]": [55,[,12],[11]],
		"/invite/[shortCode]/success": [56,[,12],[11]],
		"/join-org/[orgId]/[inviteId]/(welcome)": [57,[13]],
		"/join-org/[orgId]/[inviteId]/permissions": [58,[13]],
		"/login": [59],
		"/welcome/(welcome)": [60,[14]],
		"/welcome/(with-org)/calendars": [61,[14,15]],
		"/welcome/(with-org)/done": [62,[14,15]],
		"/welcome/(with-org)/emails": [63,[14,15]],
		"/welcome/organization": [67,[14]],
		"/welcome/(with-org)/services": [64,[14,15]],
		"/welcome/(with-org)/team": [65,[14,15]],
		"/welcome/(with-org)/upgrade": [~66,[14,15]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';